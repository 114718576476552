import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../App";
import TimeDifference from "./timeDiff";
import "./allCSSfiles/news.css";

const News = () => {
  const { news } = useContext(MyContext);
  const navigate = useNavigate();
  return (
    <div className="news">
      <h2>SOCCER NEWS</h2>
      <div className="new">
        {news &&
          news.map((n) => {
            return (
              n.catagory === "Soccer" && (
                <div
                  className="card"
                  key={n.id}
                  onClick={() => navigate(`/newsDis/${n.id}`)}
                >
                  <div className="cardimg">
                    <img src={n.imgurl} alt="news logo" />
                  </div>
                  <div className="cardBottom">
                    <span className="head">{n.headline}</span>
                    <span className="catagory">{n.catagory}</span>
                    <p className="srcN">
                      <span>
                        <TimeDifference data={n.createdAt} />
                      </span>
                      |<span>{n.author}</span>
                    </p>
                  </div>
                </div>
              )
            );
          })}
      </div>
      <div className="card_adv1"></div>
      <h2>ATLETICS NEWS</h2>
      <div className="new">
        {news &&
          news.map((n) => {
            return (
              n.catagory === "atletics" && (
                <div
                  className="card"
                  key={n.id}
                  onClick={() => navigate(`/newsDis/${n.id}`)}
                >
                  <div className="cardimg">
                    <img src={n.imgurl} alt="news logo" />
                  </div>
                  <div className="cardBottom">
                    <span className="head">{n.headline}</span>
                    <span className="catagory">{n.catagory}</span>
                    <p className="srcN">
                      <span>
                        <TimeDifference data={n.createdAt} />
                      </span>
                      |<span>{n.author}</span>
                    </p>
                  </div>
                </div>
              )
            );
          })}
      </div>
    </div>
  );
};

export default News;
