import { useContext } from "react";
import { MyContext } from "../App";
import "./allCSSfiles/football.css";

const FootballTable = () => {
  const { data } = useContext(MyContext);
  const teams = {};

  // Calculate points for each team and store last five matches
  let data1 = [];
  data &&
    data.forEach((d) => {
      if (d.status === "FT") data1.push(d);
    });

  data &&
    data1.forEach((match) => {
      if (!teams[match.homeName]) {
        teams[match.homeName] = {
          points: 0,
          wins: 0,
          logoUrl: "",
          draws: 0,
          losses: 0,
          goalsFor: 0,
          goalsAgainst: 0,
          matchesPlayed: 0,
          recentMatches: [],
        };
      }

      if (!teams[match.awayName]) {
        teams[match.awayName] = {
          points: 0,
          wins: 0,
          logoUrl: "",
          draws: 0,
          losses: 0,
          goalsFor: 0,
          goalsAgainst: 0,
          matchesPlayed: 0,
          recentMatches: [],
        };
      }

      teams[match.homeName].matchesPlayed += 1;
      teams[match.awayName].matchesPlayed += 1;
      teams[match.homeName].logoUrl = match.homeURL;
      teams[match.awayName].logoUrl = match.awayURL;

      // Store recent matches with result indicators
      const homeResult =
        match.homeGoal > match.awayGoal
          ? "W"
          : match.homeGoal < match.awayGoal
          ? "L"
          : "D";
      const awayResult =
        match.homeGoal < match.awayGoal
          ? "W"
          : match.homeGoal > match.awayGoal
          ? "L"
          : "D";

      teams[match.homeName].recentMatches.unshift({
        opponent: match.awayName,
        result: homeResult,
      });
      teams[match.awayName].recentMatches.unshift({
        opponent: match.homeName,
        result: awayResult,
      });

      // Keep only last 5 matches
      teams[match.homeName].recentMatches = teams[
        match.homeName
      ].recentMatches.slice(0, 5);
      teams[match.awayName].recentMatches = teams[
        match.awayName
      ].recentMatches.slice(0, 5);

      // Update points, goalsFor, and goalsAgainst based on match result
      if (match.homeGoal > match.awayGoal) {
        teams[match.homeName].points += 3;
        teams[match.homeName].wins += 1;
        teams[match.awayName].losses += 1;
      } else if (match.homeGoal < match.awayGoal) {
        teams[match.awayName].points += 3;
        teams[match.awayName].wins += 1;
        teams[match.homeName].losses += 1;
      } else {
        teams[match.homeName].points += 1;
        teams[match.awayName].points += 1;
        teams[match.homeName].draws += 1;
        teams[match.awayName].draws += 1;
      }

      teams[match.homeName].goalsFor += Number(match.homeGoal);
      teams[match.homeName].goalsAgainst += Number(match.awayGoal);

      teams[match.awayName].goalsFor += Number(match.awayGoal);
      teams[match.awayName].goalsAgainst += Number(match.homeGoal);
    });

  const sortedTeams = Object.keys(teams)
    .map((team) => ({
      team,
      ...teams[team],
      goalDifference: teams[team].goalsFor - teams[team].goalsAgainst,
      logoUrl: teams[team].logoUrl,
    }))
    .sort((a, b) => b.points - a.points || b.goalsFor - a.goalsFor);

  sortedTeams.forEach((team, index) => {
    team.position = index + 1;
  });

  return (
    <div className="standing">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Club</th>
            <th></th>
            <th>P</th>
            <th className="mobile-only">W</th>
            <th className="mobile-only">D</th>
            <th className="mobile-only">L</th>
            <th className="mobile-only">GF</th>
            <th className="mobile-only">GA</th>
            <th>GD</th>
            <th>Pts</th>
          </tr>
        </thead>
        <tbody>
          {sortedTeams.map((teamData, index) => (
            <tr key={index}>
              <td>{teamData.position}</td>
              <td className="team-logo">
                <img src={teamData.logoUrl} alt={`${teamData.team} Logo`} />
              </td>
              <td>{teamData.team.slice(0, 3).toUpperCase()}</td>
              <td>{teamData.matchesPlayed}</td>
              <td className="mobile-only">{teamData.wins}</td>
              <td className="mobile-only">{teamData.draws}</td>
              <td className="mobile-only">{teamData.losses}</td>
              <td className="mobile-only">{teamData.goalsFor}</td>
              <td className="mobile-only">{teamData.goalsAgainst}</td>
              <td>{teamData.goalDifference}</td>
              <td>{teamData.points}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th className="mobile-only">Form</th>
          </tr>
        </thead>
        <tbody>
          {sortedTeams.map((teamData, index) => (
            <tr key={index}>
              <td className="mobile-only">
                <ul>
                  {teamData.recentMatches.map((match, i) => (
                    <li key={i}>{match.result}</li>
                  ))}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FootballTable;
