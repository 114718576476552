import { useContext } from "react";
import Logo from "../logopre/pre.png";
import { MyContext } from "../App";
import "./allCSSfiles/football.css";

const Result = () => {
  const { data } = useContext(MyContext);
  const arr = data || [];

  const notStartedMatches = arr.filter((match) => match.status === "FT");

  return (
    <div className="result">
      <div className="prem-logo">
        <img src={Logo} alt="Premier League logo" />
        <p>
          <span>Ethiopian Premier League</span>
          <span>2023/2024</span>
        </p>
      </div>
      {notStartedMatches.length > 0 ? (
        notStartedMatches.map((match) => (
          <div className="fix" key={match.id}>
            <div className="item2">
              <span className="leftName">{match.homeName}</span>
              <img src={match.homeURL} alt={match.homeName + " logo"} />

              <button className="goal">
                {match.homeGoal + " - " + match.awayGoal}
              </button>
              <img src={match.awayURL} alt={match.awayName + " logo"} />
              <span className="rightName">{match.awayName}</span>
            </div>
            <div>
              <p className="item3">
                <span>{match.stadium} stadium</span>
                <span>{match.date}</span>
              </p>
            </div>
          </div>
        ))
      ) : (
        <p className="notif">No match to show</p>
      )}
    </div>
  );
};

export default Result;
