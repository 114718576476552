import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { MyContext } from "../App";
import TimeDifference from "./timeDiff";

const Card = ({
  content,
  catagory,
  headline,
  author,
  createdAt,
  imgurl,
  index,
  id,
}) => {
  const cardSizes = [1, 2, 3, 4];
  const size = cardSizes[index % cardSizes.length];

  const cardClassName = `card itm${size}`;
  const navigate = useNavigate();
  return (
    <div className={cardClassName} onClick={() => navigate(`/newsDis/${id}`)}>
      <div className="cardimg">
        <img src={imgurl} alt={headline} />
      </div>
      <div className="cardBottom">
        <span className="head">{headline}</span>
        <span className="catagory">{catagory}</span>
        <p className="srcN">
          <span>
            <TimeDifference data={createdAt} />
          </span>
          |<span>{author}</span>
        </p>
      </div>
    </div>
  );
};
const Comp4 = () => {
  const { news } = useContext(MyContext);
  const itemsToDisplay = news && news.slice(2, 6);
  return (
    <>
      <div className="main-content">
        {news &&
          itemsToDisplay.map((item, index) => (
            <Card key={index} {...item} index={index} />
          ))}
      </div>
    </>
  );
};

export default Comp4;
