import { useState } from "react";
import { NavLink } from "react-router-dom";
import "./allCSSfiles/nav1.css";

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <nav className={`navbar ${isMenuOpen ? "menu-open" : ""}`}>
      <div className="logo">
        <h2>Soccer Youtopia</h2>
      </div>

      <div className="menu-bar" onClick={toggleMenu}>
        {isMenuOpen ? (
          ""
        ) : (
          <>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </>
        )}
      </div>

      <div className={`buttons ${isMenuOpen ? "open" : ""}`}>
        <div className="logo-mini">
          <h2>Soccer Youtopia</h2>
          <div className="close-sign" onClick={closeMenu}>
            &#10006;
          </div>
        </div>
        <NavLink className="nav-link" to="home" onClick={closeMenu}>
          Home
        </NavLink>
        <NavLink className="nav-link" to="news" onClick={closeMenu}>
          News
        </NavLink>
        <NavLink className="nav-link" to="fixture" onClick={closeMenu}>
          Fixture
        </NavLink>
        <NavLink className="nav-link" to="result" onClick={closeMenu}>
          Result
        </NavLink>
        <NavLink className="nav-link" to="table" onClick={closeMenu}>
          Table
        </NavLink>
        {/* <NavLink className="nav-link" to="vote" onClick={closeMenu}>
          Vote
        </NavLink> */}
      </div>
    </nav>
  );
};

export default Navbar;
