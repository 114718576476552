import { useContext } from "react";
import { MyContext } from "../App";
import {
  getplayerNominee,
  getmanagerNominee,
  getkeeperNominee,
} from "../services/footballData";
import "./allCSSfiles/nominee.css";

const Nominees = ({ data }) => {
  const { player, keeper, manager } = useContext(MyContext);
  const { userID } = data;
  const handlePlayer = (p) => {
    const voter = { ...p, userID };
    return getplayerNominee(voter);
  };
  const handleKeeper = (p) => {
    const voter = { ...p, userID };
    return getkeeperNominee(voter);
  };
  const handleManager = (p) => {
    const voter = { ...p, userID };
    return getmanagerNominee(voter);
  };
  return (
    <div className="nominee">
      <h1>Best player of the year</h1>
      <div className="player">
        {player &&
          player.map((p) => (
            <div className="card_container" key={p._id}>
              <div className="img_container">
                <img src={p.imgurl} alt="Nominee player" />
                <p className="text_container">
                  <span>Name {p.name}</span>
                  <span>club {p.club}</span>
                  <span>Position {p.position}</span>
                  <span>Goal {p.goal}</span>
                  <span>Vote {p.vote}</span>
                </p>
              </div>
              <button onClick={() => handlePlayer(p)} className="btn-vote">
                Vote
              </button>
            </div>
          ))}
      </div>
      <h1>Best Goalkeeper of the year</h1>
      <div className="player">
        {keeper &&
          keeper.map((p) => (
            <div className="card_container" key={p._id}>
              <div className="img_container">
                <img src={p.imgurl} alt="Nominee player" />
                <p className="text_container">
                  <span>{p.name}</span>
                  <span>{p.club}</span>
                  <span>{p.position}</span>
                  <span>Vote-{p.vote}</span>
                </p>
              </div>

              <button onClick={() => handleKeeper(p)} className="btn-vote">
                Vote
              </button>
            </div>
          ))}
      </div>
      <h1>Best club Manager of the year</h1>
      <div className="player">
        {manager &&
          manager.map((p) => (
            <div className="card_container" key={p._id}>
              <div className="img_container">
                <img src={p.imgurl} alt="Nominee player" />
                <p className="text_container">
                  <span>{p.name}</span>
                  <span>{p.club}</span>
                  <span>{p.achievement}</span>
                  <span>Vote-{p.vote}</span>
                </p>
              </div>

              <button onClick={() => handleManager(p)} className="btn-vote">
                Vote
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Nominees;
