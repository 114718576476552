import { Outlet } from "react-router-dom";
import Navbar from "./navBar";
import Footer from "./footer";

const Initial = () => {
  return (
    <div className="all-container">
      <div className="card_adv1"></div>
      <Navbar />
      <div className="home-container">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Initial;
