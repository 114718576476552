import Comp1 from "./homcomp1";
import Comp2 from "./homcomp2";
import Comp3 from "./homcomp3";
import Comp4 from "./homcomp4";
import "./allCSSfiles/home.css";

const Home1 = () => {
  return (
    <>
      <Comp1 />
      <Comp2 />
      <div className="card_adv1"></div>
      <Comp3 />
      <Comp4 />
    </>
  );
};

export default Home1;
