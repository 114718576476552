import { useEffect, useState } from "react";
import { getDatas } from "../services/footballData";

export default function useData() {
  const [data, setData] = useState();

  useEffect(() => {
    const getData = async () => {
      const { data } = await getDatas();
      setData(data);
    };
    getData();
  }, []);
  return data;
}
