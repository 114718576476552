import { useState, useEffect } from "react";

const TimeDifference = ({ data }) => {
  const [timeDifference, setTimeDifference] = useState("");

  useEffect(() => {
    const calculateTimeDifference = () => {
      const now = new Date();
      const past = new Date(data);
      const timeDiff = now.getTime() - past.getTime();

      const seconds = Math.floor(timeDiff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      let differenceString = "";
      if (days > 0) {
        differenceString = `${days}d`;
      } else if (hours > 0) {
        differenceString = `${hours}h`;
      } else if (minutes > 0) {
        differenceString = `${minutes}m`;
      } else {
        differenceString = `0m `;
      }

      setTimeDifference(differenceString);
    };

    calculateTimeDifference();

    // Refresh every second to update the time difference
    const intervalId = setInterval(() => {
      calculateTimeDifference();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [data]);

  return <span>{timeDifference}</span>;
};

export default TimeDifference;
