import http from "./httpService";
import config from "../config.json";

const apiFootball = config.apiUrl + "/round";
const apiNews = config.apiUrl + "/news";
const apiPlayer = config.apiUrl + "/bestplayer";
const apiManager = config.apiUrl + "/bestmanager";
const apiKeeper = config.apiUrl + "/bestkeeper";
const apiGoal = config.apiUrl + "/goal";
const apiRed = config.apiUrl + "/redcard";
const apiYellow = config.apiUrl + "/yellowcard";

export async function getDatas() {
  try {
    return await http.get(apiFootball);
  } catch (error) {
    alert(error);
  }
}
export async function getGoal() {
  try {
    return await http.get(apiGoal);
  } catch (error) {
    alert(error);
  }
}
export async function getRed() {
  try {
    return await http.get(apiRed);
  } catch (error) {
    alert(error);
  }
}
export async function getYellow() {
  try {
    return await http.get(apiYellow);
  } catch (error) {
    alert(error);
  }
}
export async function getNews() {
  try {
    return await http.get(apiNews);
  } catch (error) {
    alert(error);
  }
}
export async function getplayerNominees() {
  try {
    return await http.get(apiPlayer);
  } catch (error) {
    alert(error);
  }
}
export async function getplayerNominee(data) {
  try {
    const data1 = { ...data };
    delete data1.id;
    return await http.put(apiPlayer + "/" + data.id, data1);
  } catch (error) {
    alert(error);
  }
}
export async function getmanagerNominees() {
  try {
    return await http.get(apiManager);
  } catch (error) {
    alert(error);
  }
}
export async function getmanagerNominee(data) {
  try {
    const data1 = { ...data };
    delete data1.id;
    return await http.put(apiManager + "/" + data.id, data1);
  } catch (error) {
    alert(error);
  }
}
export async function getkeeperNominees() {
  try {
    return await http.get(apiKeeper);
  } catch (error) {
    alert(error);
  }
}
export async function getkeeperNominee(data) {
  try {
    console.log(data);
    const data1 = { ...data };
    delete data1.id;
    return await http.put(apiKeeper + "/" + data.id, data1);
  } catch (error) {
    alert(error);
  }
}
