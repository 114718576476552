import React, { useState, useCallback } from "react";
import { FacebookLoginButton } from "react-social-login-buttons";
import { LoginSocialFacebook } from "reactjs-social-login";
import Nominees from "./nominees";
import "./allCSSfiles/nominee.css";

const Vote = () => {
  const [authData, setAuthData] = useState({ provider: "", profile: null });

  const onLogoutSuccess = useCallback(() => {
    setAuthData({ provider: "", profile: null });
    sessionStorage.removeItem("accessToken");
  }, []);

  return (
    <>
      {authData.provider && authData.profile ? (
        <div>
          <Nominees data={authData.profile} />
          <button onClick={onLogoutSuccess}>Logout</button>
        </div>
      ) : (
        <div
          className={`vote ${
            authData.provider && authData.profile ? "hide" : ""
          }`}
        >
          <LoginSocialFacebook
            scope="public_profile,email"
            isOnlyGetToken
            appId="762390709435568"
            onResolve={({ provider, data }) => {
              setAuthData({ provider, profile: data });
              sessionStorage.setItem("accessToken", data.accessToken);
            }}
            onReject={(err) => {
              console.error("Social login error:", err);
            }}
          >
            <h1>Vote For your Hero</h1>
            <p>
              we do have three catagories to give your vote for.we are taking
              your vote through facebook account. So,you'r expected to login in
              to your facebook using the button below. For the purpose of
              security,we provided terms and condition below.
            </p>
            <a href="termsAndcondition">Terms and conditions</a>
            <div className="fb-btn">
              <FacebookLoginButton />
            </div>
          </LoginSocialFacebook>
        </div>
      )}
    </>
  );
};

export default Vote;
