import React, { createContext, useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import useData from "./customHook/useData";
import useNews from "./customHook/useNews";
// import usePlayer from "./customHook/usePlayer";
// import useManager from "./customHook/useManager";
// import useKeeper from "./customHook/useKeeper";
import Home from "./routes/home1";
import News from "./routes/news";
import Fixture from "./routes/fixture";
import Table from "./routes/table";
import Result from "./routes/result";
import Initial from "./routes/initial";
import NewsDis from "./routes/newsDis";
import TemsAndcon from "./routes/termsAndcondition";
import Vote from "./routes/vote";
import "./App.css";

const MyContext = createContext();

const App = () => {
  const [data, setData] = useState(null);
  const [news, setNews] = useState(null);
  // const [player, setPlayer] = useState(null);
  // const [keeper, setKeeper] = useState(null);
  // const [manager, setManager] = useState(null);

  const fetchData = useData();
  const fetchNews = useNews();
  // const fetchPlayer = usePlayer();
  // const fetchManager = useManager();
  // const fetchKeeper = useKeeper();

  useEffect(() => {
    // setManager(fetchManager);
    // setKeeper(fetchKeeper);
    // setPlayer(fetchPlayer);
    setData(fetchData);
    setNews(fetchNews);
  }, [fetchData, fetchNews]);

  return (
    <MyContext.Provider value={{ data, news }}>
      <Routes>
        <Route path="/" element={<Initial />}>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="news" element={<News />} />
          <Route path="newsDis/:id" element={<NewsDis />} />
          <Route index element={<Fixture />} />
          <Route path="fixture" element={<Fixture />} />
          <Route path="result" element={<Result />} />
          <Route path="table" element={<Table />} />
          <Route path="vote" element={<Vote />} />
          <Route path="termsAndcondition" element={<TemsAndcon />} />
        </Route>
      </Routes>
    </MyContext.Provider>
  );
};

export { App, MyContext };
