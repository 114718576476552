import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../App";
import TimeDifference from "./timeDiff";
import "./allCSSfiles/news.css";

const NewsDis = () => {
  const { news } = useContext(MyContext);

  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  useEffect(() => {
    const getUpdate = async () => {
      try {
        const arr = news || [];
        const match = await arr.find((m) => m.id === Number(id));
        setData(match);
      } catch (error) {
        alert(error);
      }
    };
    getUpdate();
  }, [news, id]);
  const date = new Date(data.createdAt);
  const formattedDate = `${date.getDate()} ${date.toLocaleString("default", {
    month: "short",
  })} ${date.getFullYear()}`;
  return (
    <>
      {news && (
        <div className="newsdis">
          <img src={data.imgurl} alt="news logo" />
          <div className="newstext">
            <h3>{data.headline}</h3>
            <hr />
            <span>{data.content}</span>
            <p>
              <span>
                {formattedDate}|{data.author}
              </span>
            </p>
          </div>
        </div>
      )}
      <div className="news">
        <h2>Related News</h2>
        <div className="new">
          {news &&
            news.map((n) => {
              return (
                n.catagory === data.catagory &&
                n.id !== id && (
                  <div
                    className="card"
                    key={n.id}
                    onClick={() => navigate(`/newsDis/${n.id}`)}
                  >
                    <div className="cardimg">
                      <img src={n.imgurl} alt="news logo" />
                    </div>
                    <div className="cardBottom">
                      <span className="head">{n.headline}</span>
                      <span className="catagory">{n.catagory}</span>
                      <p className="srcN">
                        <span>
                          <TimeDifference data={n.createdAt} />
                        </span>
                        |<span>{n.author}</span>
                      </p>
                    </div>
                  </div>
                )
              );
            })}
        </div>
      </div>
    </>
  );
};

export default NewsDis;
