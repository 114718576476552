import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { MyContext } from "../App";
import TimeDifference from "./timeDiff";

const Comp1 = () => {
  const { news } = useContext(MyContext);
  const navigate = useNavigate();
  const itemsToDisplay = news && news.slice(1, 2);
  return (
    <div className="com1">
      {news &&
        itemsToDisplay.map((n) => (
          <div
            key={n.id}
            className="card_l"
            onClick={() => navigate(`/newsDis/${n.id}`)}
          >
            <div className="l_img">
              <img src={n.imgurl} alt={n.headline} />
            </div>
            <div className="l_text">
              <span className="l_head">{n.headline}</span>
              <span className="l_catagory">{n.catagory}</span>
              <span className="l_content">{n.content}</span>
              <p className="l_srcN">
                <span>
                  <TimeDifference data={n.createdAt} />
                </span>
                |<span>{n.author}</span>
              </p>
            </div>
          </div>
        ))}
      <div className="card_adv"></div>
    </div>
  );
};

export default Comp1;
