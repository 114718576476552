import { useEffect, useState } from "react";
import { getNews } from "../services/footballData";

export default function useNews() {
  const [data, setData] = useState();

  useEffect(() => {
    const getData = async () => {
      const { data } = await getNews();
      const news = data.reverse();
      setData(news);
    };
    getData();
  }, []);
  return data;
}
