import "./allCSSfiles/nominee.css";

const TemsAndcon = () => {
  return (
    <div className="Conditions">
      <p className="term-head">Terms and Conditions</p>
      <p className="terms">
        <span className="head">Welcome to YOUTOPIA SPORTS</span>
        <span>
          By logging into our app using your Facebook account, you agree to the
          following terms and conditions:
        </span>
        <span className="head">1.Account Access and Information: </span>
        <span>
          By logging in with your Facebook account, you grant us permission to
          access certain information from your Facebook profile. This includes
          your name, profile picture, email address, and any other information
          you have made available on Facebook.
        </span>
        <span className="head">2.Use of Information:</span>
        <span>
          We may use the information obtained from your Facebook profile to
          create and manage your account on our app, personalize your
          experience, and communicate with you regarding our services.
        </span>
        <span className="head">3.Privacy:</span>
        <span>
          We are committed to protecting your privacy. We will not share your
          Facebook information with third parties without your consent, except
          as required by law or as necessary to provide our services.
        </span>
        <span className="head">4.Security:</span>
        <span>
          While we take measures to protect your information, please be aware
          that no security measures are perfect or impenetrable. We cannot
          guarantee the security of your Facebook information transmitted to our
          app.
        </span>
        <span className="head">5.User Responsibilities:</span>
        <span>
          You are responsible for maintaining the confidentiality of your
          Facebook login credentials and for all activities that occur under
          your account. Notify us immediately if you suspect any unauthorized
          access to your account.
        </span>
        <span className="head">6.Termination:</span>
        <span>
          You may terminate your account at any time by contacting us or through
          the app settings. We reserve the right to terminate accounts that
          violate our terms of service or for any other reason at our
          discretion.
        </span>
        <span className="head">7.Changes to Terms:</span>
        <span>
          We may update these terms and conditions from time to time. We will
          notify you of any changes by posting the new terms on our app. Your
          continued use of our app after such modifications will constitute your
          acknowledgment of the modified terms and agreement to abide and be
          bound by the modified terms.
        </span>
        <span className="head">8.ContactUs: </span>
        <span>
          If you have any questions or concerns about these terms and
          conditions, please contact us at
          <a href="#">info@youtopiaplc.com</a>. By logging in with your Facebook
          account, you acknowledge that you have read, understood, and agree to
          be bound by these terms and conditions.
        </span>

        <span className="head">Enjoy using YOUTOPIA SPORTS!</span>
      </p>
    </div>
  );
};

export default TemsAndcon;
